<template>
    <div>
        <UILabel :label="label" :rand_id="id || rand_id" :isFocused="isFocused" :error="error" :class="label_class" :help_text="help_text"/>
        <div v-if="description" class="text-sm mb-3">{{ description }}</div>
        <div class="relative flex items-center self-center" :class="{
            'ring-2 rounded-iq' : isFocused && styled,
            'ring-calypso' : !error,
            'ring-red-600' : error,
            'w-fit' : type == 'color'
        },input_class">
            <UISelect v-if="languages.length > 0" :search_enabled="false" key="user_filter" :source="languages" class="mr-5" @changedata="onLanguageChange"/>
            <div 
                class="rounded-iq p-2 pr-0 border rounded-r-none border-r-0 pointer-none select-none"
                :class="{
                    'border-gray-300 bg-gray-50' : styled && !error,
                    'border-red-100 bg-red-50' : styled && error,
                    'border-transparent' : isFocused,
                    'border-gray-300' : !isFocused
                }"
                v-if="props.prepend"
            >{{ prepend }}</div>
            <input 
                @focus="focus"
                @blur="blur"
                :id="id || rand_id"
                :placeholder="placeholder" 
                :type="type" 
                ref="el"
                :step="type =='number'?step:''"
                v-model="inputVal"
                :tabIndex="tabIndex"
                @input="handleInput" 
                @change="handleChange"
                @keyup.enter="enter"
                class="w-full rounded-iq focus:outline-none focus:border-transparent"
                :class="{ 
                    'focus:ring-red-600': error, 
                    'focus:ring-calypso': !error,
                    'focus:ring-red-600 border-gray-300 rounded-l-none border-l-0 pl-0': prepend,
                    'focus:ring-red-600 border-gray-300 rounded-r-none border-r-0 pr-0': append,
                    'focus:ring-calypso': !prepend && !append,
                    'border border-gray-300 bg-gray-50' : styled && !error,
                    'border border-red-100 bg-red-50' : styled && error,
                    'bg-transparent !p-0' : !styled,
                    'font-bold' : font == 'bold',
                    'font-light' : font == 'light',
                    'p-2' : size == 'normal',
                    'p-1 text-sm' : size == 'sm',
                    'text-lg' : size == 'lg',
                    'text-xl' : size == 'xl',
                    'text-2xl' : size == '2xl',
                    'text-5xl' : size == '5xl'
                }"
                autocomplete="disabled"
                :disabled="disabled"
                :list="type == 'color' ? 'presetColors' : null"
            >
            <datalist id="presetColors" v-if="type == 'color'">
                <option>#000000</option>
                <option>#808080</option>
                <option>#C0C0C0</option>
                <option>#FFFFFF</option>
                <option>#800000</option>
                <option>#FF0000</option>
                <option>#808000</option>
                <option>#FFFF00</option>
                <option>#008000</option>
                <option>#008080</option>
                <option>#00FF00</option>
                <option>#00FFFF</option>
                <option>#000080</option>
                <option>#0000FF</option>
                <option>#800080</option>
                <option>#FF00FF</option>
            </datalist>
            <SearchIcon v-if="type == 'search' && (!inputVal || inputVal.length == 0)" class="w-5 h-5 text-gray-400 w-5 h-5 absolute right-3" />
            <XIcon v-if="type == 'search' && inputVal && inputVal.length > 0" class="w-5 h-5 text-gray-400 w-5 h-5 absolute right-3 opacity-70 hover:opacity-100 cursor-pointer" @click="reset" />
            <div 
            :class="isFocused ? 'border-transparent':'border-gray-300'"
            class="rounded-iq p-2 pl-0 border bg-gray-50 rounded-l-none border-l-0 pointer-none select-none" v-if="props.append" >{{ append }}</div>
        </div>
        <div class="validation_error" v-if="error">{{errors[0].$message}}</div>
    </div>
    
</template>

<script setup>
    import {computed,ref,watch} from 'vue'
    import { SearchIcon, SelectorIcon, XIcon } from '@heroicons/vue/solid'
    import { AsYouType,parsePhoneNumber } from 'libphonenumber-js'
    
    const props = defineProps({
        modelValue: [String,Number],
        placeholder: String,
        type:{
            type:String,
            default:'text'
        },
        step:{
            type:Number,
            default:1
        },
        errors:Array,
        label:String,
        label_class:String,
        input_class:String,
        prepend:String,
        append:String,
        country:String,
        styled:{
            type:Boolean,
            default:true
        },
        lazy:{
            type:Boolean,
            default:false
        },
        size:{
            type:String,
            default:'normal'
        },
        font:{
            type:String,
            default:'normal'
        },
        id:String,
        tabIndex:[String,Number],
        languages:{
            type:Array,
            default:[]
        },
        help_text:String,
        description:String,
        disabled:{
            type:Boolean,
            default:false
        }
    })

    const error = computed(() => props.errors && props.errors.length > 0)

    const isFocused = ref(false)
    
    const inputVal = ref('')
    const el = ref(null)
    watch(() => props.modelValue, (data) => {
			inputVal.value = data
		},{ immediate: true }
    )

    const emit = defineEmits(['update:modelValue','blur','enter','update:modelValueChange','focus'])

    let rand_id = computed(()=>'input_' + (Math.floor(Math.random() * 1000000000)))

    const handleInput = () => {
        if(props.type == 'phone') {
            //let a = parsePhoneNumber(inputVal.value, props.country)
            //inputVal.value = a.formatNational()
        }
        
        
        //inputVal.value = new AsYouType('TR').input(inputVal.value)
        if(props.lazy) return
        emit('update:modelValue', inputVal.value)
    }
    const handleChange = () => {
        emit('update:modelValue', inputVal.value)
        //emit('update:modelValueChange', inputVal.value)
    }

    const reset = () => {
        inputVal.value = ''
        handleInput()
    }
    
    const enter = () => {
        emit('enter')
    }

    const onLanguageChange = () => {

    }

    const blur = () => {
        isFocused.value = false
        emit('blur')
    }

    const focus = () => {
        isFocused.value = true
        emit('focus')
    }

    defineExpose({el})
</script>

<style scoped>
    input:focus + svg {
        fill: rgba(0, 164, 189, 1)
    }
    [type="search"]::-webkit-search-cancel-button,
    [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
    }
    input[type="color"] {
        -webkit-appearance: none;
        border: none;
        width: 32px;
        height: 32px;
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
        padding: 0;
    }
    input[type="color"]::-webkit-color-swatch {
        border: none;
    }
</style>
